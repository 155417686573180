<template>
  <div
    :class="{ 'common-modal': true, 'modal-center': !meetDetails.room }"
    id="modal-error-msg"
  >
    <div class="modal-container">
      <div class="modal-body">
        <div id="participant-permission">
          <h3 :class="msgTypeClass">
            <span class="alert-header">
              {{
                globalAlert.type.charAt(0).toUpperCase() +
                  globalAlert.type.slice(1) +
                  "!"
              }}
            </span>
            <a class="icon-close cursorPointer" @click="hideGlobalAlert">
              <i class="bi bi-x"></i>
            </a>
          </h3>
          <p class="gen-msg" v-html="globalAlert.msg"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      globalAlert: "getGlobalAlert"
    }),

    ...mapGetters("meet", {
      meetDetails: "getMeetDetails"
    }),

    /**
     * Computed property for returning the class name depending on message type
     */
    msgTypeClass() {
      let className = "gen-msg";
      if ("alert" === this.globalAlert.type) {
        className = "alert-msg";
      } else if ("error" === this.globalAlert.type) {
        className = "error-msg";
      } else if ("success" === this.globalAlert.type) {
        className = "success-msg";
      } else if ("warning" === this.globalAlert.type) {
        className = "warning-msg";
      }

      return className;
    }
  },
  methods: {
    /**
     * Resetting the global alert
     */
    hideGlobalAlert() {
      this.$store.dispatch("hideGlobalAlert");
    }
  }
};
</script>
<style scoped>
body,
html {
  min-height: auto;
}
#modal-error-msg.modal-center {
  margin: 0 auto;
  margin-top: -85px;
  top: 50%;
  bottom: auto;
  left: 50%;
  right: 50%;
  margin-left: -202px;
}
.modal-entry-cta {
  float: right;
}
#modal-error-msg {
  width: 400px;
  margin: 0;
  position: fixed !important;
  left: 20px;
  bottom: 80px;
  top: unset;
  z-index: 999999999;
  padding: 0 10px 20px;
}
#modal-error-msg .modal-body {
  padding: 5px;
}
.icon-close {
  right: 0;
  position: absolute;
  right: 24px;
  font-size: 24px;
  color: #fff;
  top: 10px;
}
h3 {
  margin: 0 0 10px 0;
  color: #eeb747;
}
@media (max-width: 640px) {
  #modal-error-msg {
    min-width: 200px;
    max-width: 100%;
    width: auto;
    right: 20px;
  }
  .alert-header {
    display: none;
  }
  .gen-msg {
    margin: 10px 0 0;
  }
  #modal-error-msg {
    padding-bottom: 0;
  }
  .common-modal .modal-container {
    padding-top: 0;
    padding-left: 0;
  }
  .icon-close {
    right: 7px;
  }
  .alert-msg {
    border-bottom: 0px;
    padding-bottom: 10px;
  }
  #modal-error-msg.modal-center {
    top: auto;
    bottom: 70px;
    margin-left: -156px;
    min-width: 290px;
    width: auto;
  }
}
</style>
